<template>
  <v-container class="pa-0">
    <div class="d-flex pb-6" style="gap: 1rem;">
      <iframe
        :src="mytripUrl"
        class="mytrip-iframe"
        title="My Trip">
      </iframe>
      <v-row class="content-main">
          <v-col class="widgets">
            <div class="widget-panel">
              <div class="panel-title" style="margin-bottom: 1rem;">
                {{ $t('main.CREATE_MYJOURNEY_PAGE_ACTION') }}
              </div>
              <DestinationWidgetCreateMyTrip
                :departureType="destinationDetail.departureType"
                :departureId="destinationDetail.departureId"
                :destinationsTypes="destinationDetail.destinationsTypes"
                :destinationIds="destinationDetail.destinationIds"
                :destinationsNames="destinationDetail.destinationsNames"
                :citizenshipCountryIds="destinationDetail.citizenshipCountryIds"
                :transitType="destinationDetail.transitType"
                :transitId="destinationDetail.transitId"
                :dateStart="destinationDetail.dateStart"
                :dateEnd="destinationDetail.dateEnd"
                :restrictionType="destinationDetail.restrictionType"
              />
            </div>
            <div class="widget-panel">
              <div class="panel-title">
                {{ $t("main.FEEDBACK_ACTION") }}
              </div>
              <DestinationWidgetFeedback
                :title="feedbackTitle"
                :departureName="destinationDetail.departureName"
                :destinationsNames="destinationDetail.destinationsNames"
                :citizenshipsCountry="destinationDetail.citizenshipsCountry"
                :transitName="destinationDetail.transitName"
                :dateStart="destinationDetail.dateStart"
                :dateEnd="destinationDetail.dateEnd"
                :restrictionType="destinationDetail.restrictionType"
              />
            </div>
            <div class="widget-panel">
              <div class="panel-title">
                {{ $t('main.VISA') }}
              </div>
              <DestinationWidgetVisa
                :iso2="destinationDetail.destinationsIso2[0]"
                :nationalityId="destinationDetail.citizenshipCountryIds[0]"
              />
            </div>
          </v-col>
        </v-row>
    </div>
  </v-container>
</template>

<script>
import { backendMyJourneyUrl } from '@/settings';
import DestinationWidgetCreateMyTrip from '@/components/destination/widgets/DestinationWidgetCreateMyTrip'
import DestinationWidgetFeedback from '@/components/destination/widgets/DestinationWidgetFeedback'
import DestinationWidgetVisa from '@/components/destination/widgets/DestinationWidgetVisa'
import {mapState} from "vuex";

export default {
  name: "DestinationTravelRestriction",
  components: {
    DestinationWidgetCreateMyTrip,
    DestinationWidgetFeedback,
    DestinationWidgetVisa
  },
  props: {
    url: {
      type: String,
      required: true
    },
    destinationDetail: {
      type: Object,
      required: true
    },
    feedbackTitle : {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      language: state => state.uiStore.language,
    }),
    mytripUrl(){
      return backendMyJourneyUrl + '/+' + this.url + '?lang=' + this.language
    },
    hasContent() {
      return this.$hasPermission('a3m_core.DestinationManagerVisumPoint') ||
             this.$hasPermission('a3m_core.DestinationManagerDvkg') ||
             this.$hasPermission('a3m_core.DestinationManagerCIBTVisas') ||
             this.$hasPermission('a3m_core.DestinationManagerVisaGate');
    },
  },
}
</script>

<style scoped>

  .widget-panel {
    background: #FFFFFF;
    padding: 18px 18px;
    border-radius: 10px;
    margin-bottom: 25px;
  }

  .panel-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #113b71;
    border-bottom: 0.7rem;
  }

  .mytrip-iframe {
    width: 100%;
    border-width: 0;
    height: calc(100vh - 250px);
  }

  .widgets {
    overflow-x: hidden;
    width: 22vw;
    height: calc(100vh - 230px);
    padding-block: 0;
    padding-right: 0;
    margin-block: 12px;
  }

</style>