<template>
  <v-row no-gutters style="height: calc(100vh - 263px)">
    <v-col cols="4" class="event-display-left">
      <div class="event-display-left-scroll" ref="EventList">
        <v-checkbox v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings')" v-model="showLive" class="live-checkbox" :label="$t('main.SHOW_LIVE_EVENTS')"></v-checkbox>
        <EventItemsList
          :items="dateListItems"
          :allow-click="true"
          :timeAtCategory="true"
          @select="openEventPage"
        />
        <div v-if="!dateListItems.length">
          {{ $t('main.NO_ENTRIES_FOUND') }}
        </div>
      </div>
    </v-col>
    <v-col class="pa-0 iframe-title">
      <div class="iframe-wrapper">
          <div v-if="event" class="iframe-title-content">
            <div class="d-flex">
              <v-spacer></v-spacer>

              <span v-if="!isPinned" class="pt-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="pin" text v-on="on" :loading="pinIsLoading"><v-icon>push_pin</v-icon></v-btn>
                  </template>
                  <span>{{ $t("main.PIN_GMEVENT_ACTION") }}</span>
                </v-tooltip>
              </span>

              <span v-if="isPinned" class="pt-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="unpin" text v-on="on" :loading="pinIsLoading"><v-icon>done</v-icon></v-btn>
                  </template>
                  <span>{{ $t("main.UNPIN_GMEVENT_ACTION") }}</span>
                </v-tooltip>
              </span>
            </div>
            <div class="event-url">Url: <a :href="event.staticPageUrl" target="_blank">{{ event.staticPageUrl }}</a></div>
            <div class="pl-2">
              <BosysDisplay :event="event" :bookingsEvent="bookingsEvent" :showDetails="false" v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings') && event"/>
            </div>
          </div>
          <iframe
            v-if="event"
            :src="event.staticPageUrl"
            class="event-content"
            frameborder="0"
          />

          <div
            v-if="!event"
            class="empty-icon"
          >
            <v-icon size="300" color="grey lighten-3">event</v-icon>
          </div>
        </div>
    </v-col>
  </v-row>
</template>

<script>
import EventService from '@/services/EventService';
import EventItemsList from "./EventItemsList.vue";
import {mapActions, mapGetters} from "vuex";
import BosysDisplay from "../bosys/BosysDisplay.vue";

export default {
  name: "EventDisplay",
  components: {
    BosysDisplay,
    EventItemsList,
  },
  props: {
    events: {
      type: Array,
      required: true
    },
    liveEvents: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      event: null,
      showLive: false,
      pinIsLoading: false,
      bookingsEvent: null,
    }
  },
  computed: {
    ...mapGetters({
      eventsPinnedGetById: 'eventsLatestSignificantUpdatesPinnedStore/getById',
    }),
    isPinned() {
      if (this.event) {
        let found = this.eventsPinnedGetById(this.event.id)
        if (found) {
          return true
        }
      }
      return false
    },
    dateListItems() {
      let newsEvents = []
      let events = null
      if (this.showLive) {
        events = this.liveEvents
      } else {
        events = this.events
      }
      events.forEach(({ id, event, travelersNow, travelersPotentiallyAffected }) => {
        let newsEvent = {}

        let icon = EventService.getIconFileNameByCategorty(event.category.code, event.level)

        newsEvent.id = id
        newsEvent.text = event.title
        newsEvent.createdAt = event.significantUpdateAt
        newsEvent.category = event.category.name

        newsEvent.categoryIcon = icon

        newsEvent.isNew = event.countUpdates == 0

        newsEvent.staticPageUrl = event.staticPageUrl

        newsEvent.gmUniqueEventId = event.gmUniqueEventId

        newsEvent.travelersNow = travelersNow
        newsEvent.travelersPotentiallyAffected = travelersPotentiallyAffected

        newsEvents.push(newsEvent)
      })

      return newsEvents
    }
  },
  methods: {
    ...mapActions({
      eventsPinnedPin: 'eventsLatestSignificantUpdatesPinnedStore/pin',
      eventsPinnedUnpin: 'eventsLatestSignificantUpdatesPinnedStore/unpin',
      eventsLatestSignificantUpdatesPinnedFetchAll: 'eventsLatestSignificantUpdatesPinnedStore/fetchAll',
      bosysFetchAll: 'bosysStore/fetchAll',
    }),
    openEventPage(event) {
      this.event = event
      this.fetchBookings()
    },
    scrollToTop() {
      this.$refs.EventList.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
    },
    pin() {
      this.pinIsLoading = true
      this.eventsPinnedPin(this.event.id)
        .then(() => this.eventsLatestSignificantUpdatesPinnedFetchAll({ variables: { countryFilter: false }, force: true}))
        .then(() => this.pinIsLoading = false)
    },
    unpin() {
      this.pinIsLoading = true
      this.eventsPinnedUnpin(this.event.id)
        .then(() => this.eventsLatestSignificantUpdatesPinnedFetchAll({ variables: { countryFilter: false }, force: true}))
        .then(() => this.pinIsLoading = false)
    },
    fetchBookings(){
      this.bosysFetchAll({ variables: { gmUniqueEventId: this.event.gmUniqueEventId }, force: true })
        .then(response => {
          this.bookingsEvent = response;
        })
    }
  }
}
</script>

<style scoped>
  .event-display-left {
    position: relative;
    background: #F7F9FC;
  }

  .event-display-left-scroll {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 12px 24px 0;
    scrollbar-width: thin;
  }

  .event-content {
    width: 100%;
    height: 100%;
  }

  .empty-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f7;
  }

  .live-checkbox{
    margin: 0;
  }

  .iframe-title {
    background: #f5f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .iframe-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    width: 100%;
    height: 100%;
  }

  .iframe-title-content {
    background: #FFFFFF;
  }

  .event-url {
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
  }
</style>