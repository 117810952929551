<template>
  <div style="font-size: 90%">
    <div v-if="!done">
      <v-text-field
        class="pt-6"
        v-model="content.title"
        :label="$t('main.FEEDBACK_TITLE')"
      />
      <div style="color: grey">
        {{ $t('main.EMAIL') }}
        <span style="color: red; position: relative; top: -3px; left: -3px">*</span>
      </div>
      <v-text-field
        v-model="content.email"
        :label="$t('main.YOUR_EMAIL_ADDRESS')"
        required
        single-line
        dense
        :rules="emailRules"
      />

      <v-textarea v-model="content.feedback" class="pt-6" outlined :label="`${$t('main.TEXT_INPUT_LABEL')}*`" dense/>

      <div style="text-align: right;">
        <v-btn
          :disabled="!valid"
          :loading="sending"
          @click="sendFeedback"
          color="primary"
          small
        >
          {{ $t("main.SEND_FEEBACK") }}
        </v-btn>
      </div>
    </div>

    <div v-if="done" class="pt-6" style="display: flex; flex-direction: column">
      {{ $t("main.FEEDBACK_DONE_TEXT") }}

      <v-btn
        @click="reactivateFeedback"
        color="primary"
        small
        class="mt-2"
      >
        {{ $t("main.FEEDBACK_ACTION") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DestinationWidgetFeedback",
  props: {
    title: {
      type: String,
      required: true
    },
    departureName: {
      type: String,
      required: true
    },
    destinationsNames: {
      type: Array,
      required: true
    },
    transitName: {
      type: String,
      required: false
    },
    citizenshipsCountry: {
      type: Array,
      required: true
    },
    dateStart: {
      type: String,
      required: true
    },
    dateEnd: {
      type: String,
      required: true
    },
    restrictionType: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      sending: false,
      done: false,
      emailRules: [
        v => !v || /.+@.+\..+/.test(v) || this.$t('main.EMAIL_ADDRESS_IS_INVALID'),
      ],
      content: {
        title: '',
        email: '',
        feedback: ''
      }
    }
  },
  mounted() {
    this.resetContent()
  },
  computed: {
    ...mapState({
      organisation: state => state.authStore.organisation,
      languages: state => state.uiStore.languages,
      language: state => state.uiStore.language
    }),
    valid() {
      const emailValid = this.emailRules.every(rule => rule(this.content.email) === true || rule(this.content.email) === undefined);
      return this.content.email !== '' && this.content.feedback && this.content.title !== '' && emailValid
    }
  },
  methods: {
    resetContent() {
      this.content.title = this.title
      this.content.email = ""
      this.content.feedback = ""
    },
    reactivateFeedback() {
      this.resetContent()
      this.done = false
    },
    sendFeedback() {
      let text = []
      const tripDetails = []

      if (this.content.email === '') {
        alert(this.$t('main.EMAIL_ADDRESS_IS_INVALID'))
        return
      }

      tripDetails.push("Departure: " + this.departureName)
      tripDetails.push(this.transitName !== null ? "Transit: " + this.transitName : "Transit: -");
      tripDetails.push("Destination(s): " + this.destinationsNames.join(", "))
      tripDetails.push("Purpose of travel: " + this.restrictionType)
      tripDetails.push("Period of travel: " + this.$options.filters.i18n_dateToGerman(this.dateStart)  + " - "
          + this.$options.filters.i18n_dateToGerman(this.dateEnd))
      tripDetails.push("Nationalities: " + this.citizenshipsCountry.join(", "))

      let tripDetailsText = tripDetails.join("\n")

      text.push("Allgemeines Feedback:\n" + this.content.feedback)

      let feedbackText = text.join("\n\n")

      feedbackText = "E-Mail: " + this.content.email + "\n\n" + tripDetailsText + "\n\n" + feedbackText

      this.sending = true
      this.$destinationRepository.sendFeedback(this.content.title, feedbackText)
        .then(() => {
          this.sending = false
          this.done = true
        })
    }
  },
  watch: {
    title() {
      this.resetContent()
    }
  }
}
</script>

<style scoped>

  .v-textarea /deep/ .v-label {
    position: relative;
  }

  .v-textarea /deep/ .v-label::after {
    color: red;
    content: '*';
    position: absolute;
    transform: translate(-100%, 0);
  }
</style>
