<template>
  <div class="destination-navigation">
    <div>
      <v-list class="navigation-list" shaped>
        <v-list-item-group color="primary" mandatory>
          <v-list-item @click="selectComponent('DestinationTravelRestriction')">
            <v-list-item-icon>
              <SvgIcon type="mdi" :path="accountIcon"></SvgIcon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("main.TRAVEL_RESTRICTIONS") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="selectComponent('CountryInformation')"  v-if="$hasPermission('a3m_core.DestinationManagerCDB')">
            <v-list-item-icon>
              <SvgIcon type="mdi" :path="mapMarkerIcon"></SvgIcon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("main.COUNTRY_INFORMATION_TAB") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="selectComponent('EventDisplay')" v-if="$hasPermission('a3m_core.DestinationManagerEvents')">
            <v-list-item-icon>
              <SvgIcon type="mdi" :path="mytripMapIcon"></SvgIcon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t("main.GMEVENTS") }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list class="navigation-list mt-6" shaped v-if="currentComponent === 'CountryInformation'">
        <div class="destinations-title">{{ $t("main.DESTINATIONS") }}</div>
        <v-list-item-group color="primary" mandatory>
          <v-list-item
            v-for="(destination, index) in combinedDestinations"
            :key="index"
            @click="onDestinationSelect(destination)"
          >
            <v-list-item-title>
              {{ destination.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>

    <DestinationTravelRestriction v-show="currentComponent === 'DestinationTravelRestriction'" :url="destinationDetail.url" :destinationDetail="destinationDetail" :feedbackTitle="feedbackTitle" />
    <CountryInformation v-if="$hasPermission('a3m_core.DestinationManagerCDB')" v-show="currentComponent === 'CountryInformation'"
                          :countryIso2="countryIso2 || destinationDetail.destinationsIso2[0]" :countriesIso2="destinationDetail.destinationsIso2"/>
    <EventDisplay v-if="$hasPermission('a3m_core.DestinationManagerEvents')" v-show="currentComponent === 'EventDisplay'" :events="destinationDetail.events"
                  :liveEvents="destinationDetail.liveEvents" ref="eventDisplay"/>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import {mdilAccount} from '@mdi/light-js';
import {mdiMapMarkerStar, mdiMap} from '@mdi/js';
import CountryInformation from '@/components/countryinformation/CountryInformation'
import EventDisplay from '@/components/event/EventDisplay'
import DestinationTravelRestriction from '@/components/destination/DestinationTravelRestriction'

export default {
  name: "DestinationResultNavigation",
  components: {
    SvgIcon,
    CountryInformation,
    EventDisplay,
    DestinationTravelRestriction
  },
  props: {
    destinationDetail: {
      type: Object,
      required: true
    },
    feedbackTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      accountIcon: mdilAccount,
      mapMarkerIcon: mdiMapMarkerStar,
      mytripMapIcon: mdiMap,
      currentComponent: 'DestinationTravelRestriction',
      countryIso2: null
    }
  },
  computed: {
    combinedDestinations() {
      return this.destinationDetail.destinationsIso2.map((iso2, index) => ({
        iso2,
        name: this.destinationDetail.destinationsNames[index]
      }));
    }
  },
  methods: {
    selectComponent(componentName) {
      this.currentComponent = componentName
      if (this.currentComponent === 'EventDisplay') {
        if (this.$refs.eventDisplay && this.$refs.eventDisplay.scrollToTop) {
            this.$refs.eventDisplay.scrollToTop();
        }
      }
    },
    onDestinationSelect(destination) {
      this.countryIso2 = destination.iso2;
    }
  },
}
</script>

<style scoped>
  .destination-navigation {
    gap: 1%;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .navigation-list {
    border-radius: 10px !important;
    height: fit-content;
  }

  .destinations-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #113b71;
    padding-left: 16px;
  }
</style>