<template>
  <div>
    <div v-if="$hasPermission('a3m_core.DestinationManagerVisumPoint')">
      <div class="visa">
        <div class="visa-content">
          <img src="@/assets/logo_visumpoint.png" class="visumpoint-logo" alt="visumpoint visa"/>
          <span class="pl-4 visa-text">{{ $t('main.VISUMPOINT_ACTION') }}</span>
        </div>
        <v-btn class="visa-button" color="primary" text @click="openVisumPoint"><v-icon color="primary">open_in_new</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
    </div>

    <div v-if="$hasPermission('a3m_core.DestinationManagerDvkg')">
      <div class="visa">
        <div class="visa-content">
          <div class="logo-container">
            <img src="@/assets/logo_dvkg.png" class="dvkg-logo" alt="dvkg visa"/>
          </div>
          <span class="pl-4 visa-text">{{ $t('main.DVKG_ACTION') }}</span>
        </div>
        <v-btn class="visa-button" color="primary" text @click="openDvkg"><v-icon color="primary">open_in_new</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
    </div>

    <div v-if="$hasPermission('a3m_core.DestinationManagerCIBTVisas')">
      <div class="visa">
        <div class="visa-content">
          <img src="@/assets/logo_cibt.png" class="cibt-logo" alt="cibt visa"/>
          <span class="pl-4 visa-text">{{ $t('main.CIBT_ACTION') }}</span>
        </div>
        <v-btn class="visa-button" color="primary" text href="https://cibtvisas.de/visas" target="_blank"><v-icon color="primary">open_in_new</v-icon></v-btn>
      </div>
      <v-divider></v-divider>
    </div>

    <div v-if="$hasPermission('a3m_core.DestinationManagerVisaGate')">
      <div class="visa">
        <div class="visa-content">
          <div class="logo-container">
            <img src="@/assets/logo_visagate.png" class="visagate-logo" alt="visagate visa"/>
          </div>
          <span class="pl-4 visa-text">{{ $t('main.VISAGATE_ACTION') }}</span>
        </div>
        <v-btn class="visa-button" color="primary" text @click="openVisaGate"><v-icon color="primary">open_in_new</v-icon></v-btn>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "DestinationWidgetVisa",
  props: {
    iso2: {
      type: String,
      required: true
    },
    nationalityId: {
      type: String,
      required: false
    },
  },
  mounted(){
    this.countriesFetchAll()
  },
  data() {
    return {
       visaGateEndPoint: "https://widget.visa-gate.com/",
       defaultEndPoint: "https://dvkg.de/de/external/a3mobile/countries/information/new",
       purposes: {
         "TOURISM": "1",
         "BUSINESS": "2"
       },

      cdbVisumPointEndPoint: "https://www.visumpoint.de/visapflichtw.php",
      cdbVisumPointEndPointPasskey: "WGlQTXB2VzQ3bW1ad1diNVdqMTJ4NFVRMTE3R2pMOHVUTTRGK3VtZ05jZy9iM3NHNmxwTUh5MVBoYkNieDVYbEN6Zm1Yc0kxLzJWaDVFcGxrMkppVXc9PTo6CgN7r62W8CASsP9uxXeTDw=="
    };
  },
  computed: {
    ...mapState({
      language: state => state.uiStore.language,
      organisation: state => state.authStore.organisation,
      countries: state => state.countriesStore.entries,
      restrictionType: state => state.uiStore.restrictionType
    }),
    endPointDvkg() {
      return this.organisation.dvkgUrl || this.defaultEndPoint;
    },
    nationality() {
      let nationalityIso2 = this.countries.find(item => item.id === this.nationalityId)

      if (nationalityIso2) {
        return nationalityIso2.iso2
      }

      return 'DE'
    }
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll"
    }),
    openVisaGate() {
      let url = `${this.visaGateEndPoint}?&country=${this.iso2}`;
      window.open(url, '_blank')
    },
    openDvkg() {
      let url = `${this.endPointDvkg}?&country=${this.iso2}`;
      window.open(url, '_blank')
    },
    openVisumPoint() {
      let url = `${this.cdbVisumPointEndPoint}?passkey=${this.cdbVisumPointEndPointPasskey}&lang=${this.language}&land_pflicht=${this.iso2}&art_pflicht=${this.purposes[this.restrictionType]}&national_pflicht=${this.nationality}`;
      window.open(url, '_blank')
    }
  }
};
</script>

<style scoped>
  .visumpoint-logo {
    width: 56px;
  }

  .cibt-logo {
    width: 56px;
  }

  .visagate-logo {
    width: 100%;
    height: 73%;
    object-fit: cover;
    object-position: 0 0;
  }

  .dvkg-logo {
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: -16px 0;
  }

  .logo-container {
    min-width: 56px;
    width: 56px;
    height: 56px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .visa {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
  }

  .visa-content {
    display: flex;
    align-items: center;
  }

  .visa-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }

  .visa-button {
    margin-right: -19px;
  }
</style>