<template>
  <div class="result-view">
    <div class="header pb-8">
      <div class="search-container" v-if="!isLoading">
          <v-img
            cover
            height="200px"
            :src="require('@/assets/prop-background.jpg')"
            class="search-bar-img"
            style="border-radius: 10px"
          >
            <div class="search-container">
              <DestinationSearchBar :isResultPage="true" @search="search" @show-error-message="showErrorMessage"/>
            </div>
          </v-img>
      </div>
    </div>
    <!--------------------------- LOADING ---------------------->
    <div v-if="isLoading" style="flex: 1">
      <v-row style="flex: 1">
        <v-col>
          <v-skeleton-loader
            class="mx-auto"
            type="card"
          />
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
          <v-skeleton-loader
            class="mx-auto"
            type="article"
          />
        </v-col>
      </v-row>      
    </div>

    <!--------------------------- RESULT ---------------------->
    <div class="destination-result-main" v-if="!isLoading">
      <DestinationResultNavigation
        :destinationDetail="destinationDetail"
        :feedbackTitle="feedbackTitle"
      />
    </div>

    <v-snackbar v-model="errorMessage" :timeout="2000" color="error">{{ $t('main.SEARCH_DATE_ERROR') }}</v-snackbar>
  </div>
</template>

<script>
import DestinationSearchBar from '@/components/destination/DestinationSearchBar'
import DestinationResultNavigation from '@/components/destination/DestinationResultNavigation'

import {mapMutations, mapState} from "vuex";

export default {
  name: "DestinationResultView",
  components: {
    DestinationSearchBar,
    DestinationResultNavigation
  },
  data() {
    return {
      destinationDetail: null,
      isLoading: true,
      contentTab: 0,
      actionPanel: 0,
      errorMessage: false
    }
  },
  mounted() {
    this.SET_AUTOCOMPLETE_FORM(true)
    if (Object.keys(this.$route.params).length !== 0){
      this.queryDestination(this.$route.params)
    } else if (this.searchParams) {
      this.queryDestination(this.searchParams)
    } else {
      this.$router.push({ name: 'index' })
    }
  },
  computed: {
    ...mapState({
      searchParams: state => state.uiStore.searchParams,
    }),
    feedbackTitle() {
        return this.$t('main.COUNTRY') + ': ' + this.destinationDetail.destinationsNames[0]
    },
    hasContent() {
      return this.$hasPermission('a3m_core.DestinationManagerVisumPoint') ||
             this.$hasPermission('a3m_core.DestinationManagerDvkg') ||
             this.$hasPermission('a3m_core.DestinationManagerCIBTVisas') ||
             this.$hasPermission('a3m_core.DestinationManagerVisaGate');
    },
  },
  methods: {
    ...mapMutations({
      SET_SEARCH_PARAMS: 'uiStore/SET_SEARCH_PARAMS',
      SET_AUTOCOMPLETE_FORM: 'uiStore/SET_AUTOCOMPLETE_FORM',
    }),

    async queryDestination(params) {
      this.isLoading = true

      var transitType = null;
      var transitId = null;

      if (params.transitType != "UNDEFINED") {
        transitType = params.transitType;
        transitId = params.transitId;
      }

      let destinationDetail = await this.$destinationRepository.getDestinationDetail(
        params.departureType,
        params.departureId,
        params.destinationsTypes,
        params.destinationIds,
        params.citizenshipIds,
        params.restrictionType,
        params.dateStart,
        params.dateEnd,
        transitType,
        transitId,
      )
      this.destinationDetail = destinationDetail
      this.isLoading = false
    },
    search(searchData) {
      this.SET_SEARCH_PARAMS(searchData)
      this.queryDestination(searchData);
    },
    showErrorMessage(errorMessage) {
      this.errorMessage = errorMessage
    }
  },
  watch: {
    '$route.params': function () {
      this.queryDestination(this.$route.params)
    },
  }
}
</script>

<style scoped>

  .destination-result-main {
    display: flex;
    padding-inline: 10px;
    gap: 15px;
    height: calc(100vh - 250px);
  }

  .result-view {
    display: flex;
    flex-direction: column;
    background: #F7F9FC;
    padding: 18px 18px 0;
    overflow-y: hidden;
  }

</style>