<template>
  <div class="country-information">
    <div v-for="(country, index) in countriesIso2" :key="index" style="height: 100%" v-show="country === countryIso2">
      <iframe
        :src="url(country)"
        title="Country information"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "DestinationCountryInformation",
  props: {
    countryIso2: {
      type: String,
      required: true
    },
    countriesIso2: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user,
      language: state => state.uiStore.language,
    }),
  },
  methods: {
    url(countryIso2) {
      return 'https://global-monitoring.com/cdb/countries/' + countryIso2 + '/' + this.user.cdbAccessKey + '?lang=' + this.language
    }
  }
}
</script>

<style scoped>

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .country-information {
    width: 100%;
  }

</style>